import { FC, useContext, useRef, useState } from "react";

import {
  convertCentsToDollars,
  convertDollarsToCents,
} from "../../../../../../utils";

import { QueryWalletContext } from "../../../../core/QueryWalletResponseProvider";
import { useIntl } from "react-intl";
import { UserContext } from "../../../../core/UserContextProvider";
import { ShowError } from "../../../../../errors/ShowError";
import { useQueryClient } from "react-query";
import clsx from "clsx";
import { trimSpacesString } from "../../../../../../../_metronic/helpers";

const WithdrawEditModalForm: FC = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const [withdrowMode, setWithdrowMode] = useState("app");
  const [withdrowModePage, setWithdrowModePage] = useState<string | null>(null);

  const amountBkTnputRef = useRef<HTMLInputElement>(null);
  const amountBankInputRef = useRef<HTMLInputElement>(null);

  const totpTnputRef = useRef<HTMLInputElement>(null);

  const walletAdressInputRef = useRef<HTMLInputElement>(null);
  const walletFullNameInputRef = useRef<HTMLInputElement>(null);
  const walletBankNameInputRef = useRef<HTMLInputElement>(null);

  const {
    freewinData,
    isAllLoading,
    withdrawDepositFreewin,
    withdrawDepositBank,
    error,
    setError,
  } = useContext(QueryWalletContext);

  const { TotpTransStatus, setModalMode } = useContext(UserContext);

  const accessibleFreewinData = convertCentsToDollars(
    (freewinData?.availableBalance as number) <= 0
      ? 0
      : (freewinData?.availableBalance as number)
  );

  const maxWalletValueFreewin = () => {
    if (amountBkTnputRef.current) {
      amountBkTnputRef.current.value = accessibleFreewinData.toString();
    }
  };

  const maxWalletValueBank = () => {
    if (amountBankInputRef.current) {
      amountBankInputRef.current.value = accessibleFreewinData.toString();
    }
  };

  const createSendDataFreewin = (cents: number) => {
    if (TotpTransStatus) {
      return {
        verify_data: {
          verify_method: "totp",
          verify_code: totpTnputRef?.current?.value || "",
        },
        amount: {
          amount: cents,
        },
      };
    } else {
      return {
        verify_data: {
          verify_method: "smtp",
        },
        amount: {
          amount: cents,
        },
      };
    }
  };

  const createSendDataBank = (cents: number) => {
    return {
      amount: cents,
      number: walletAdressInputRef?.current?.value,
      full_name: trimSpacesString(walletFullNameInputRef?.current?.value),
      bank_name: trimSpacesString(walletBankNameInputRef?.current?.value),
    };
  };

  const cancel = () => {
    setError(null);
    setModalMode(undefined);
    setWithdrowMode("app");
    setWithdrowModePage(null);
  };

  return (
    <>
      {withdrowModePage === null
        ? addSelectPage()
        : withdrowModePage === "app"
        ? addAppPage()
        : addBankWithdrawMenu()}
    </>
  );

  function modalButtonClickApp() {
    if (Number(amountBkTnputRef?.current?.value) < 2) {
      setError("Минимальная сумма вывода 2 USDT");
      return;
    }

    queryClient.clear();

    const cents = convertDollarsToCents(
      Number(amountBkTnputRef?.current?.value)
    );

    withdrawDepositFreewin(createSendDataFreewin(cents), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return ["wallet", "freewin-balance"].includes(
              query.queryKey as string
            );
          },
        });
        cancel();
      },
    });
  }

  function modalButtonClickBank() {
    if (Number(amountBankInputRef?.current?.value) < 100) {
      setError("Минимально доступная сумма для вывода 100$");
      return;
    }

    if (
      !amountBankInputRef?.current?.value ||
      !walletAdressInputRef?.current?.value ||
      !walletFullNameInputRef?.current?.value ||
      !walletBankNameInputRef?.current?.value
    ) {
      setError("Заполнены не все поля");
      return;
    }

    queryClient.clear();

    const cents = convertDollarsToCents(
      Number(amountBankInputRef?.current?.value)
    );

    withdrawDepositBank(createSendDataBank(cents), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return ["wallet", "freewin-balance"].includes(
              query.queryKey as string
            );
          },
        });
        cancel();
      },
    });
  }

  function addSelectPage() {
    return (
      <div className="modal-body scroll-y pb-10">
        <div data-kt-element="options" className="">
          <p className="text-muted fs-5 fw-semibold mb-10">
            Выберите способ вывода средств с баланса
          </p>

          <div className="pb-12">
            <input
              type="radio"
              className="btn-check"
              name="auth_option"
              value="app"
              checked={withdrowMode === "app"}
              onChange={(e) => {
                setWithdrowMode("app");
              }}
              id="kt_modal_two_factor_authentication_option_1"
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-2"
              htmlFor="kt_modal_two_factor_authentication_option_1"
            >
              <i className="ki-duotone ki-wallet fs-4x me-4">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <span className="d-block fw-semibold text-start">
                <span className="text-gray-900 fw-bold d-block fs-3">
                  Вывод на кошелек Freewin
                </span>
                <span className="text-muted fw-semibold fs-6">
                  Вывод криптовалюты с игрового счета на баланс кошелька
                  Freewin.
                </span>
              </span>
            </label>

            <input
              type="radio"
              className="btn-check"
              name="auth_option"
              value="bank"
              checked={withdrowMode === "bank"}
              onChange={(e) => {
                setWithdrowMode("bank");
              }}
              id="kt_modal_two_factor_authentication_option_2"
            />
            <label
              className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
              htmlFor="kt_modal_two_factor_authentication_option_2"
            >
              <i className="ki-duotone ki-bank fs-4x me-4">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
              <span className="d-block fw-semibold text-start">
                <span className="text-gray-900 fw-bold d-block fs-3">
                  Вывод на банковскую карту РФ
                </span>
                <span className="text-muted fw-semibold fs-6">
                  Вывод в рублях на банковскую карту РФ. Обращаем Ваше внимание
                  на то, что курс не фиксированный и рассчитывается на момент
                  отправки с учетом всех комиссий.
                </span>
              </span>
            </label>
          </div>

          <button
            className="btn btn-primary w-100"
            onClick={() => {
              setWithdrowModePage(withdrowMode);
            }}
          >
            Продолжить
          </button>
        </div>
      </div>
    );
  }

  function addAppPage() {
    return (
      <div>
        <div className="fv-row mb-7 p-3">
          <label className="d-flex align-items-center fs-5 fw-semibold mb-3 ms-2">
            <span className="">
              {intl.formatMessage({ id: "GLOBAL.ENTER_AMOUNT" })}
            </span>
          </label>
          <div className="mb-2" style={{ position: "relative", width: "100%" }}>
            <input
              type="number"
              ref={amountBkTnputRef}
              className="form-control form-control-lg form-control-solid"
              style={{ width: "100%" }}
              name="amountBkTnputRef"
              placeholder="0.00 $"
              onChange={(e) => {}}
            />

            {accessibleFreewinData > 0 && (
              <button
                onClick={maxWalletValueFreewin}
                className="btn btn-sm fw-bold btn-light-success"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10%",
                  transform: "translateY(-50%)",
                }}
              >
                MAX
              </button>
            )}
          </div>

          {/* end::Input */}

          {TotpTransStatus && (
            <div className="fv-row mt-7">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span className="">
                  {intl.formatMessage({ id: "GLOBAL.OTP" })}
                </span>
                <span
                  className="ms-1"
                  data-bs-toggle="tooltip"
                  title="“Включить подтверждение через код можно в разделе “Настройки”"
                >
                  <i className="fas fa-exclamation-circle text-gray-500 fs-6"></i>
                </span>
              </label>
              <input
                type="number"
                ref={totpTnputRef}
                className="form-control form-control-lg form-control-solid"
                name="totpTnputRef"
                placeholder="XXX XXX"
                onChange={(e) => {}}
              />
            </div>
          )}

          <div>
            <i
              className="fas fa-exclamation-circle mt-5 fs-7 ms-1"
              data-bs-toggle="tooltip"
              title="Specify your unique app name"
            ></i>{" "}
            <span className="text-muted w-25">
              Вывод средств производится вручную и занимает от 6 минут до 6
              часов.
              <br />
              Комиссия за вывод средств составляет{" "}
              <span className="text-white">1 USDT</span>
              {accessibleFreewinData > 0 && (
                <h6 className="mt-3 ms-2 text-white">
                  Доступно: {accessibleFreewinData} {" USDT"}
                </h6>
              )}
            </span>
          </div>
        </div>
        {/* end::Input group */}

        {error && ShowError(error)}

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className="d-flex aligin-items-center justify-content-between pt-10 2 mx-5">
          <button
            type="reset"
            onClick={() => cancel()}
            className="d-block  btn btn-light  px-6"
          >
            Отмена
          </button>

          <button
            type="button"
            disabled={isAllLoading}
            className="d-block ms-auto btn btn-primary  px-6"
            onClick={modalButtonClickApp}
          >
            {isAllLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {intl.formatMessage({ id: "PROFILE.WITHDRAW_ACTION" })}
          </button>
        </div>
      </div>
    );
  }

  function addBankWithdrawMenu() {
    return (
      <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
        <div
          id="kt_app_sidebar_menu_wrapper"
          className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
          data-kt-scroll="true"
          data-kt-scroll-activate="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
          data-kt-scroll-wrappers="#kt_app_sidebar_menu"
          data-kt-scroll-offset="5px"
          data-kt-scroll-save-state="true"
        >
          <div
            className="menu menu-column menu-rounded menu-sub-indention"
            id="#kt_app_sidebar_menu"
            data-kt-menu="true"
            data-kt-menu-expand="false"
          >
            <div
              className={clsx(
                "menu-item",
                { "here show": true },
                "menu-accordion"
              )}
              data-kt-menu-trigger="click"
            >
              <div className="fv-row mb-2 p-2">
                <label className="d-flex align-items-center fs-5 fw-semibold mb-3 ms-2">
                  <span className="">
                    {intl.formatMessage({ id: "GLOBAL.ENTER_AMOUNT" })}.{" "}
                    <span className="text-muted w-25 required">
                      {" "}
                      Минимально доступная сумма 100$.
                    </span>
                  </span>
                </label>
                <div
                  className="mb-2"
                  style={{ position: "relative", width: "100%" }}
                >
                  <input
                    type="number"
                    ref={amountBankInputRef}
                    className="form-control form-control-lg form-control-solid"
                    style={{ width: "100%" }}
                    name="amountBankInputRef"
                    placeholder="0.00 $"
                    onChange={(e) => {}}
                  />

                  {accessibleFreewinData > 0 && (
                    <button
                      onClick={maxWalletValueBank}
                      className="btn btn-sm fw-bold btn-light-success"
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      MAX
                    </button>
                  )}
                </div>

                <div className="ps-2">
                  <i
                    className="fas fa-exclamation-circle mt-5 fs-7 ms-1"
                    data-bs-toggle="tooltip"
                    title="Specify your unique app name"
                  ></i>{" "}
                  <span className="text-muted w-25">
                    Вывод средств производится вручную и занимает от 6 минут до
                    6 часов.
                    <br />
                    Комиссия за вывод средств составляет{" "}
                    <span className="text-white">1 USDT</span>
                    {accessibleFreewinData > 0 && (
                      <h6 className="mt-3 ms-1 text-white">
                        Доступно: {accessibleFreewinData} {" USDT"}
                      </h6>
                    )}
                  </span>
                </div>
              </div>

              <div
                className={clsx("menu-sub menu-sub-accordion", {
                  "menu-active-bg": withdrowMode,
                })}
              >
                <div className="mb-8">
                  <label className="d-flex align-items-center fs-5 fw-semibold mt-4 mb-2 ms-2">
                    <span className="">Введите номер карты</span>
                  </label>
                  <input
                    type="number"
                    ref={walletAdressInputRef}
                    className="form-control form-control-lg form-control-solid"
                    name="walletAdressInputRef"
                    placeholder="0000 0000 0000 0000"
                  />
                </div>

                <div className="mb-8 ">
                  <label className="d-flex align-items-center fs-5 fw-semibold mt-4 mb-2 ms-2">
                    <span className="">Введите Ф.И.О. получателя</span>
                  </label>
                  <input
                    type="text"
                    ref={walletFullNameInputRef}
                    className="form-control form-control-lg form-control-solid"
                    name="walletFullNameInputRef"
                    placeholder="Ф.И.О."
                  />
                </div>

                <div className="mb-4">
                  <label className="d-flex align-items-center fs-5 fw-semibold mt-4 mb-2 ms-2 ">
                    <span className="">Введите название банка</span>
                  </label>
                  <input
                    type="text"
                    ref={walletBankNameInputRef}
                    className="form-control form-control-lg form-control-solid"
                    name="walletBankNameInputRef"
                    placeholder="Название банка"
                  />
                </div>

                {error && ShowError(error)}
              </div>
            </div>
          </div>
          <div className="d-flex aligin-items-center justify-content-between pt-10 mb-2 mx-5">
            <button
              type="reset"
              onClick={() => cancel()}
              className="d-block  btn btn-light  px-6"
            >
              Отмена
            </button>

            <button
              type="button"
              disabled={isAllLoading}
              className="d-block ms-auto btn btn-primary  px-6"
              onClick={modalButtonClickBank}
            >
              {isAllLoading && (
                <span
                  className="spinner-border spinner-border-sm me-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {intl.formatMessage({ id: "PROFILE.WITHDRAW_ACTION" })}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export { WithdrawEditModalForm };

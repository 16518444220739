import { FC, useContext, useRef } from "react";

import {
  convertCentsToDollars,
  convertDollarsToCents,
} from "../../../../../../utils";

import { QueryWalletContext } from "../../../../core/QueryWalletResponseProvider";
import { useIntl } from "react-intl";
import { UserContext } from "../../../../core/UserContextProvider";
import { ShowError } from "../../../../../errors/ShowError";
import { useQueryClient } from "react-query";
import { trimSpacesString } from "../../../../../../../_metronic/helpers";

const WalletEditModalForm: FC = () => {
  const intl = useIntl();

  const queryClient = useQueryClient();

  const amountBkTnputRef = useRef<HTMLInputElement>(null);
  const totpTnputRef = useRef<HTMLInputElement>(null);
  const walletAdressInputRef = useRef<HTMLInputElement>(null);

  const {
    freewinData,
    walletData,
    isAllLoading,
    withdrawTron,
    error,
    setError,
  } = useContext(QueryWalletContext);
  const { TotpTransStatus, setModalMode } = useContext(UserContext);

  const accessibleWalletData = convertCentsToDollars(
    (walletData?.balance as number) - 100 <= 0
      ? 0
      : (walletData?.balance as number) - 100
  );

  const maxWalletValue = () => {
    if (amountBkTnputRef.current) {
      amountBkTnputRef.current.value = accessibleWalletData.toString();
    }
  };

  const createSendDataFreewin = (cents: number) => {
    if (TotpTransStatus) {
      return {
        verify_data: {
          verify_method: "totp",
          verify_code: totpTnputRef?.current?.value || "",
        },
        receiver_data: {
          receiver:
            trimSpacesString(walletAdressInputRef?.current?.value) || "",
          amount: cents,
        },
      };
    } else {
      return {
        verify_data: {
          verify_method: "smtp",
        },
        receiver_data: {
          receiver:
            trimSpacesString(walletAdressInputRef?.current?.value) || "",
          amount: cents,
        },
      };
    }
  };

  const cancel = () => {
    setError(null);
    setModalMode(undefined);
  };

  const accessibleFreewinData = convertCentsToDollars(
    (freewinData?.availableBalance as number) <= 0
      ? 0
      : (freewinData?.availableBalance as number)
  );

  return (
    <>
      <div>
        <div className="fv-row mb-7 p-3">
          <label className="d-flex align-items-center fs-5 fw-semibold mb-3 ms-2">
            <span className="">
              {intl.formatMessage({ id: "GLOBAL.ENTER_AMOUNT" })}
            </span>
          </label>
          <div className="mb-2" style={{ position: "relative", width: "100%" }}>
            <input
              type="number"
              ref={amountBkTnputRef}
              className="form-control form-control-lg form-control-solid"
              style={{ width: "100%" }}
              name="amountBkTnputRef"
              placeholder="0.00 $"
              onChange={(e) => {}}
            />

            {accessibleWalletData > 0 && (
              <button
                onClick={maxWalletValue}
                className="btn btn-sm fw-bold btn-light-success"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10%",
                  transform: "translateY(-50%)",
                }}
              >
                MAX
              </button>
            )}
          </div>

          <label className="d-flex align-items-center fs-5 fw-semibold mt-9 mb-3">
            <span className="">Введите адрес кошелька USDT TRC20</span>
          </label>
          <input
            type="text"
            ref={walletAdressInputRef}
            className="form-control form-control-lg form-control-solid"
            name="walletAdressInputRef"
            placeholder="TEEf2wm8QuexzTRUXWVqvSQ89EyWrgAYBc"
          />

          {TotpTransStatus && (
            <div className="fv-row mt-5">
              <label className="d-flex align-items-center fs-5 fw-semibold mb-2">
                <span className="">
                  {intl.formatMessage({ id: "GLOBAL.OTP" })}
                </span>
                <span
                  className="ms-1"
                  data-bs-toggle="tooltip"
                  title="Включить подтверждение через код можно в разделе “Настройки”"
                >
                  <i className="fas fa-exclamation-circle text-gray-500 fs-6"></i>
                </span>
              </label>
              <input
                type="number"
                ref={totpTnputRef}
                className="form-control form-control-lg form-control-solid"
                name="totpTnputRef"
                placeholder="XXX XXX"
                onChange={
                  (e) => {}
                  // updateData({
                  //   appBasic: {
                  //     appName: e.target.value,
                  //     appType: data.appBasic.appType,
                  //   },
                  // })
                }
              />
            </div>
          )}

          <div>
            <i
              className="fas fa-exclamation-circle mt-3 fs-7 ms-1"
              data-bs-toggle="tooltip"
              title="Specify your unique app name"
            ></i>{" "}
            <span className="text-muted w-25">
              Комиссия за операцию <span className="text-white">1 USDT</span>
              {accessibleFreewinData > 0 && (
                <h6 className="mt-3 ms-2 text-white">
                  Доступно: {accessibleWalletData} {" USDT"}
                </h6>
              )}
            </span>
          </div>

          {/* end::Input */}
        </div>
        {/* end::Input group */}

        {error && ShowError(error)}

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className="d-flex aligin-items-center justify-content-between pt-10 mb-2 mx-5">
          <button
            type="reset"
            onClick={() => cancel()}
            className="d-block  btn btn-light  px-6"
          >
            Отмена
          </button>

          <button
            type="button"
            disabled={isAllLoading}
            className="d-block ms-auto btn btn-primary  px-6"
            onClick={modalButtonClick}
          >
            {isAllLoading && (
              <span
                className="spinner-border spinner-border-sm me-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {intl.formatMessage({ id: "PROFILE.WITHDRAW_ACTION" })}
          </button>
        </div>
      </div>
    </>
  );

  function modalButtonClick() {
    queryClient.clear();
    const cents = convertDollarsToCents(
      Number(amountBkTnputRef?.current?.value)
    );

    withdrawTron(createSendDataFreewin(cents), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            return ["wallet", "freewin-balance"].includes(
              query.queryKey as string
            );
          },
        });
        cancel();
      },
    });
  }
};

export { WalletEditModalForm };

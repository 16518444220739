import React, { FC, createContext, useEffect, useState } from "react";
import {
  useChangeUserSettings,
  useFreeWinLogin,
  useGetUser,
} from "./_requests";
import { WithChildren } from "../../../../_metronic/helpers";
import { TotpStatus } from "../../../../api/core-contract";

interface Context {
  TotpStatus?: TotpStatus | null;
  updateTotpStatus: (TotpStatus: any) => void;
}

export const UserContext = createContext<Context | any | null>(null);

export const UserContextProvider: FC<WithChildren> = ({ children }) => {
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [modalMode, setModalMode] = useState<string | undefined>(undefined);

  const {
    data: userData,
    isFetching: isUserDataFetching,
    refetch: userDataRefetch,
  } = useGetUser();

  const { mutate: getFreeWinLogin, isLoading: isFreeWinLoginLoading } =
    useFreeWinLogin();

  const { mutate: changeUserSettings, isLoading: isChangeUserSettingsLoading } =
    useChangeUserSettings();

  const [TotpStatus, setTotpStatus] = useState<boolean | undefined | null>(
    false
  );
  const [TotpAuthStatus, setTotpAuthStatus] = useState<boolean>(false);
  const [TotpTransStatus, setTotpTransStatus] = useState<boolean>(false);
  const [locale, setLocale] = useState<string | undefined | null>(null);
  const [view, setView] = useState<string | undefined | null>(null);

  useEffect(() => {
    if (userData) {
      setTotpStatus(userData?.settings?.totp ?? null);
      setTotpAuthStatus(userData?.settings?.totp_auth ?? false);
      setTotpTransStatus(userData?.settings?.totp_trans ?? false);
      setLocale(userData?.settings?.locale ?? null);
      setView(userData?.settings?.view ?? null);

      setIsUserDataLoaded(true);
    }
  }, [userData]);

  const resetTotpStatus = (newTotpStatus: boolean) => {
    setTotpStatus(newTotpStatus);
    setTotpAuthStatus(newTotpStatus);
    setTotpTransStatus(newTotpStatus);

    changeUserSettings({
      settings: {
        locale: locale,
        view: view,
        totp: newTotpStatus,
        totp_auth: newTotpStatus,
        totp_trans: newTotpStatus,
      },
    });
  };

  const updateTotpAuthStatus = (newTotpAuthStatus: boolean) => {
    setTotpAuthStatus(newTotpAuthStatus);

    changeUserSettings({
      settings: {
        locale: locale,
        view: view,
        totp: TotpStatus,
        totp_auth: newTotpAuthStatus,
        totp_trans: TotpTransStatus,
      },
    });
  };

  const updateTotpTransStatus = (newTotpTransStatus: boolean) => {
    setTotpTransStatus(newTotpTransStatus);

    changeUserSettings({
      settings: {
        locale: locale,
        view: view,
        totp: TotpStatus,
        totp_auth: TotpAuthStatus,
        totp_trans: newTotpTransStatus,
      },
    });
  };

  const updateLocaleView = (newLocale: string, newView: string) => {
    setLocale(newLocale);
    setView(newView);

    changeUserSettings({
      settings: {
        locale: newLocale,
        view: newView,
        totp: TotpStatus,
        totp_auth: TotpAuthStatus,
        totp_trans: TotpTransStatus,
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        locale,
        view,
        updateLocaleView,
        userDataRefetch,

        TotpStatus,
        setTotpStatus,
        resetTotpStatus,

        TotpAuthStatus,
        updateTotpAuthStatus,

        TotpTransStatus,
        updateTotpTransStatus,

        isUserDataFetching,
        isChangeUserSettingsLoading,
        isFreeWinLoginLoading,
        isUserDataLoaded,
        modalMode,
        setModalMode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

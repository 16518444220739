import { FC, createContext, useContext, useEffect, useState } from "react";

import { WithChildren } from "../../../../_metronic/helpers";
import { useAuth } from "../../auth";
import {
  useDepositFreewin,
  useFreewinBalance,
  useTronWallet,
  useWallet,
  useWithdrawDepositBanks,
  useWithdrawDepositFreewin,
  useWithdrawTron,
} from "./_requests";
import { UserContext } from "./UserContextProvider";
import { DialogModal } from "../../../../_metronic/partials/modals/DialogModal";

export const QueryWalletContext = createContext<any | null>(null);

export const QueryWalletResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();

  const { TotpTransStatus } = useContext(UserContext);

  const [dialogModalData, setDialogModalData] = useState<any | null>(null);

  const [error, setError] = useState<any | null>(null);

  const { data: walletData, isLoading: isWalletDataLoading } = useWallet({
    refetchInterval: 10000,
  });

  const { data: freewinData, isLoading: isFreewinDataLoading } =
    useFreewinBalance({ enabled: !!currentUser, refetchInterval: 10000 });

  const {
    mutate: setDepositFreewin,
    isLoading: isSettingDepositFreewin,
    error: depositFreewinError,
    data: depositFreewinData,
  } = useDepositFreewin();

  const {
    mutate: withdrawDepositFreewin,
    isLoading: isWithdrawDeposFreewinitLoading,
    error: withdrawDepositFreewinError,
    data: withdrawDepositFreewinData,
  } = useWithdrawDepositFreewin();

  const {
    mutate: withdrawDepositBank,
    isLoading: isWithdrawDeposBankitLoading,
    error: withdrawDepositBankError,
    data: withdrawDepositBankData,
  } = useWithdrawDepositBanks();

  const {
    mutate: withdrawTron,
    isLoading: isWithdrawTronLoading,
    error: withdrawTronError,
    data: withdrawTronData,
  } = useWithdrawTron();

  const { data: tronWalletData, isFetching: isTronWalletFetching } =
    useTronWallet();

  // ****ERRORS****

  useEffect(() => {
    if (withdrawDepositBankError) setError(withdrawDepositBankError);
  }, [withdrawDepositBankError]);

  useEffect(() => {
    if (withdrawDepositFreewinError) setError(withdrawDepositFreewinError);
  }, [withdrawDepositFreewinError]);

  useEffect(() => {
    if (withdrawTronError) setError(withdrawTronError);
  }, [withdrawTronError]);

  useEffect(() => {
    if (depositFreewinError) setError(depositFreewinError);
  }, [depositFreewinError]);

  // ****DIALOG_MODALS****

  useEffect(() => {
    if (depositFreewinData?.amount) {
      setDialogModalData({
        title: "Внимание",
        text: "Средства поступят на игровой баланс в ближайшее время.",
      });
    }
  }, [depositFreewinData]);

  useEffect(() => {
    if (withdrawDepositFreewinData?.status === "success" && !TotpTransStatus) {
      setDialogModalData({
        title: "Внимание",
        text: "Ваша заявка на вывод средств принята, письмо с подтверждением транзакции отправлено на Вашу электронную почту.",
      });
    } else if (
      withdrawDepositFreewinData?.status === "success" &&
      TotpTransStatus
    ) {
      setDialogModalData({
        title: "Внимание",
        text: "Ваша заявка на вывод средств принята и скоро будет исполнена, ожидайте.",
      });
    }
  }, [withdrawDepositFreewinData]);

  useEffect(() => {
    if (withdrawDepositBankData?.status === "success") {
      setDialogModalData({
        title: "Внимание",
        text: "Ваша заявка на вывод средств принята, письмо с подтверждением транзакции отправлено на Вашу электронную почту.",
      });
    }
  }, [withdrawDepositBankData?.status]);

  useEffect(() => {
    if (withdrawTronData?.status === "success" && !TotpTransStatus) {
      setDialogModalData({
        title: "Внимание",
        text: "Ваша заявка на вывод средств принята, письмо с подтверждением транзакции отправлено на Вашу электронную почту.",
      });
    } else if (withdrawTronData?.status === "success" && TotpTransStatus) {
      setDialogModalData({
        title: "Внимание",
        text: "Транзакция успешно подтверждена.",
      });
    }
  }, [withdrawTronData]);

  const isAllLoading =
    isWalletDataLoading ||
    isFreewinDataLoading ||
    isSettingDepositFreewin ||
    isWithdrawTronLoading ||
    isTronWalletFetching ||
    isWithdrawDeposFreewinitLoading ||
    isWithdrawDeposBankitLoading;

  return (
    <QueryWalletContext.Provider
      value={{
        isAllLoading,
        walletData,
        isWalletDataLoading,
        freewinData,
        isFreewinDataLoading,

        setDepositFreewin,
        isSettingDepositFreewin,

        withdrawDepositFreewin,
        isWithdrawDeposFreewinitLoading,

        withdrawDepositBank,
        isWithdrawDeposBankitLoading,

        withdrawTron,
        isWithdrawTronLoading,

        tronWalletData,
        isTronWalletFetching,

        error,
        setError,
      }}
    >
      {children}

      {dialogModalData && (
        <DialogModal
          title={dialogModalData?.title}
          text={dialogModalData?.text}
          setModalData={setDialogModalData}
        />
      )}
    </QueryWalletContext.Provider>
  );
};
